@media (min-width: 576px) {
  .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
