.tags-input .ant-select-multiple {
  /* @apply !min-h-[48px] !bg-input-background; */
}
.tags-input .ant-select-selector {
  @apply !rounded !border !border-border-color !bg-input-background !py-9 !outline-none placeholder:!text-placeholder-text-color focus:!shadow-input-shadow focus:!outline-none disabled:!cursor-not-allowed disabled:!text-placeholder-text-color;
}

.tags-input .ant-select-multiple {
  @apply !rounded !bg-input-background focus:!shadow-input-shadow focus:!outline-none;
}

/* .tags-input .ant-select-focused {
  @apply !border-none !outline-none;
} */

.tags-input .ant-select-selection-overflow {
  @apply gap-[10px];
}

.tags-input .ant-select-selector {
  @apply max-h-28 overflow-y-scroll;
}

.tags-input-scroll-hidden .ant-select-selector {
  @apply max-h-full overflow-y-auto;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1adbn6x).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none;
  @apply shadow-input-shadow;
}

.tags-input
  .ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply !text-16 !font-normal !leading-24  !text-input-text-color placeholder:!text-placeholder-text-color;
}

/* .tags-input */
.ant-select-dropdown .ant-select-item-option-content {
  @apply !text-16 !font-normal !leading-24  !text-input-text-color placeholder:!text-placeholder-text-color;
}

.tags-input
  :where(.css-dev-only-do-not-override-1adbn6x).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector
  .ant-select-selection-search-input {
  @apply !text-input-text-color;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-select-multiple
  .ant-select-selection-search-input {
  min-width: unset;
}

span.anticon.anticon-check {
  @apply !text-white-heading;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply !cursor-pointer;
}
