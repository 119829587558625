.Toastify__toast-body {
    @apply !p-0;
}

.Toastify__close-button {
    @apply !opacity-100;
}

.Toastify__toast-container {
    @apply xl:!w-[469px];
}