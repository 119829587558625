@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Inter", sans-serif;
    @apply bg-gray-500;
  }
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

button:focus-visible,
input:focus-visible,
optgroup:focus-visible,
select:focus-visible,
textarea:focus-visible,
a:focus-visible {
  outline: none;
}
/*Toast alert css*/
.custom-toast-container {
  /* width: 520px !important; */
}

.Toastify__toast-body {
  padding: 0px !important;
}

.Toastify__close-button {
  opacity: 1 !important;
}

.custom-toast-container .Toastify__close-button {
  top: 0;
}

/* Pagination | reactPaginate  Start */

.pagination ul {
  display: flex;
}

.pagination ul li {
  margin-right: 16px;
}
.pagination ul li:last-child {
  margin-right: 0px;
}

.pagination ul li a {
  height: 48px;
  width: 48px;
  background-color: #fff;
  color: #667085;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination ul li:hover a {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
}

.pagination ul li.active a {
  background-color: #000aff;
  color: #fff;
}

/* Pagination | reactPaginate  End  */

/* data table pagination */

.datatable-pagination ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.datatable-pagination ul li a {
  padding: 10px;
  margin: 0 5px;
  color: #a5acba;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: block;
}
.datatable-pagination ul li.active a,
.datatable-pagination ul li a:hover {
  color: #437ef7;
}

.datatable-pagination ul li.previous {
  position: absolute;
  left: 30px;
  right: auto;
}

.datatable-pagination ul li.next {
  position: absolute;
  left: auto;
  right: 30px;
}

.datatable-pagination ul li.selected a {
  color: #437ef7;
}

.datatable-pagination ul li.next a,
.datatable-pagination ul li.previous a {
  color: #000aff;
  position: relative;
}
.datatable-pagination ul li.next-disabled a,
.datatable-pagination ul li.previous-disabled a {
  color: #a5acba;
  position: relative;
}

.datatable-pagination ul li.previous a:before,
.datatable-pagination ul li.next a:before {
  /* content: url("../public/assets/images/icons/pagination-back.svg"); */
  position: absolute;
  top: 50%;
  transform: translateY(-43%);
}

.datatable-pagination ul li.previous a:before {
  right: auto;
  left: -10px;
}
.datatable-pagination ul li.next a:before {
  left: auto;
  right: -10px;
  rotate: 180deg;
  top: 0;
}

.datatable-pagination ul li.previous-disabled a:before {
  right: auto;
  left: -10px;
  /* content: url("../public/assets/images/icons/pagination-back-disabed.svg"); */
}

.datatable-pagination ul li.next-disabled a:before {
  left: auto;
  right: -10px;
  rotate: 180deg;
  top: 0;
  /* content: url("../public/assets/images/icons/pagination-back-disabed.svg"); */
}
/* end data table pagination */

/* sidebar nav-link-active */

.nav-link.active {
  @apply bg-white-button;
}
.nav-link span {
  @apply transition duration-[0.4s];
}

.nav-link.active span {
  @apply text-gray-400;
}

.nav-link.active:hover span {
  @apply text-white-para;
}

.nav-link.active svg {
  @apply transition duration-[0.4s];
}

.nav-link.active svg path {
  stroke: #191c24;
  fill: unset;
}

.nav-link.active:hover svg path {
  stroke: #bab8b8;
  fill: unset;
}

.nav-link.featured-members.active svg path {
  stroke: none;
  fill: #191c24;
}

.nav-link.featured-members.active:hover svg path {
  stroke: none;
  fill: #bab8b8;
}
/* hover css*/
.white-link-with-icon svg path,
.white-link-with-icon span {
  @apply transition duration-[0.4s];
}

.white-link-with-icon:hover svg path {
  stroke: #fff;
}

.white-link-with-icon:hover span {
  @apply text-white;
}

.bg-card {
  background-image: url("/public/assets/images/bg-card.svg");
  background-position: right;
  background-repeat: no-repeat;
  @apply bg-gray-300;
}

.inputStyle {
  @apply h-[36px] rounded border border-border-color bg-gray-300 text-28 font-semibold leading-38 tracking-0.28 text-input-text-color placeholder:text-white-para focus:outline-none sm:h-[56px];
  /* caret-color: transparent; */
  appearance: none;
  width: 56px !important;
  @media screen and (max-width: 567px) {
    width: 36px !important;
  }
}

.settings-forget-otp-input .inputStyle {
  @apply h-auto rounded border border-border-color bg-gray-300 px-16 py-11 text-16 font-normal leading-24 tracking-0.28 text-input-text-color placeholder:text-white-para focus:outline-none;
  /* caret-color: transparent; */
  appearance: none;
  width: 100% !important;
}

.settings-forget-otp-input > div {
  @apply gap-1 sm:gap-5;
}

.inputStyle::-webkit-inner-spin-button,
.inputStyle::-webkit-outer-spin-button {
  appearance: none !important;
}

/* set-up-account bio section */
.add-section-min-height .ck-content {
  min-height: 158px;
}

.default-section-template .bio-section .ck-content {
  /* max-height: 350px; */
  overflow: auto;
}

.test234 {
  cursor: text !important;
}
.active span {
  color: #007bff;
}

.first-letter-capital::first-letter {
  text-transform: uppercase;
}

/* custom-scroll */
/* width */
.modal-scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #fff0;
}

/* Track */
.modal-scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  /* border-radius: 10px; */
  background-color: #fff0;
}

/* Handle */
.modal-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c1c1c1c8;
}

/* Apply a custom class to the number input */
/* Style the input as a text input */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove the up and down arrows in Chrome, Safari, and Edge */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove the up and down arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* truncate after 2 lines */
.ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  box-orient: vertical;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  box-orient: vertical;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  box-orient: vertical;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.ellipsis-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  box-orient: vertical;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.ck-editor__editable a {
  @apply !break-words text-blue-link underline;
}

.gradient-text {
  background: var(
    --gradients-linear,
    linear-gradient(180deg, #d06705 0%, #ef9c20 48.96%, #ecda36 97.92%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-border {
  position: relative;
}

.gradient-border::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 0.375em;
  padding: 1px;
  background: linear-gradient(
    to right,
    #d06705,
    #ef9c20,
    #ecda36
  ); /* Updated gradient */
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.custom-dashed-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2363676CFF' stroke-width='3' stroke-dasharray='6%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}

.rc-virtual-list-scrollbar-thumb {
  @apply !bg-[#c1c1c1c8];
}

.author-name:hover {
  background: linear-gradient(
    180deg,
    #d06705 0%,
    #ef9c20 48.96%,
    #ecda36 97.92%
  );
  -webkit-background-clip: text; /* Apply background to text only */
  -webkit-text-fill-color: transparent; /* Set text color to transparent */
  transition: color 0.3s ease; /* Add transition for smooth effect */
}

/* custom toggle switch css */
.ant-switch.leader-badge-toggle {
  background: #63676c !important;
  min-width: 56px;
  height: 33px;
}

/* Default Toggle Styles */
.toggle {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.toggle input {
  display: none;
}
.toggle input:checked ~ label .toggler {
  left: 0%;
}
.toggle input + label .toggler {
  left: 50%;
}
.toggle label {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: #63676c;
  border-radius: 8px;
}
.toggle .toggleLabel {
  padding: 5px;
  min-height: 36px;
  min-width: 100px;
  text-align: center;
  z-index: 1;
  flex: 1;
  transition: all 0.5s;
  font-weight: 500;
  font-size: 12px;
  color: #d4d9e0;
  justify-content: space-around;
  display: flex;
  align-items: center;
}
.toggle .toggler {
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    180deg,
    #d06705 0%,
    #ef9c20 48.96%,
    #ecda36 97.92%
  );
  transition: all 0.5s;
  border-radius: 8px;
}

/* .leader-badge-toggle input:checked ~ label .toggler {
  left: 0%;
}

.leader-badge-toggle input + label .toggler {
  left: 50%;
} */
