.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  @apply !min-w-[224px] !rounded-none !border !border-border-color !bg-gray-400 !py-7;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  @apply !p-0;
}

.ant-dropdown .ant-dropdown-menu {
  @apply !p-0;
}
