.hbgCLW:not(:last-of-type) {
  border-bottom: none !important;
}

.gocYik {
  border-bottom: none !important;
}

.jNyFuQ,
.rdt_TableRow .rdt_TableCell:last-child {
  padding: 9px 16px !important;
  flex: 0 0 auto !important;
  justify-content: end !important;
}

.bjzdIj {
  width: 120px;
}

.fwhpon,
.jbGVAn {
  justify-content: space-between !important;
}

.rdt_TableRow {
  border-bottom: none !important;
}
.rdt_TableHeadRow {
  border-bottom: none !important;
}

.sc-hmdomO {
  @apply border-b border-border-color !bg-gray-400 !text-14 !font-medium !leading-24 !text-white-para;
}
.cTsUPQ {
  @apply border-b border-border-color !bg-gray-400 !text-14 !font-medium !leading-24 !text-white-para;
}

.rdt_Table {
  @apply !table;
}

.rdt_TableHead {
  @apply !table-header-group;
}

.rdt_TableHeadRow,
.rdt_TableRow {
  @apply !table-row;
}

.rdt_TableCol,
.rdt_TableCell,
.hCsxzF {
  @apply !table-cell !align-middle;
}

.rdt_TableBody {
  @apply !table-row-group;
}
