.ant-drawer-header,.ant-drawer-body {
  @apply bg-gray-400 !p-24;
}

.ant-drawer-title {
  @apply !text-white-heading !text-16 !font-semibold !leading-24;
}

.ant-drawer-close {
  margin-inline-end: 0 !important;
}

.ant-drawer-header-title {
  @apply gap-2;
}

.ant-drawer-header {
  @apply !border-b !border-border-color;
}
.ant-drawer-close {
  @apply !p-4;
}

.ant-drawer-content-wrapper {
  @apply xl:!min-w-[460px];
}