.custom-theme .ant-select .ant-select-selector {
  border-radius: 4px !important;
  border: 1px solid #63676c !important;
  background: #32353d !important;
  color: #8c94a4 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  padding: 11px 16px !important;
  height: 48px !important;
  font-family: "Inter", sans-serif !important;
  cursor: pointer !important;
}
.select-country-error .ant-select .ant-select-selector {
  @apply !border !border-error-danger-500;
}
.ant-select-selection-search-input {
  height: auto !important;
}

.ant-select-selection-item {
  padding-inline-end: 0px !important;
}
.ant-select-selection-item span {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: #bab8b8 !important;
  margin-left: 4px !important;
}
.custom-theme .ant-select .ant-select-selector:after {
  width: 16px !important;
  height: 26px !important;
  background-image: url("../../../../public/assets/images/keyboard-arrow.svg");
  background-repeat: no-repeat;
  background-position: left;
  transform: none !important;
  border: none !important;
  visibility: visible !important;
  content: "" !important;
}
.ant-select-dropdown {
  border: 1px solid #63676c !important;
  background: #25272d !important;
  padding: 0px !important;
  font-family: "Inter", sans-serif !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.ant-select-dropdown .ant-select-item {
  border-bottom: 1px solid #63676c !important;
  border-radius: 0px !important;
  min-height: auto !important;
  padding: 12px 16px !important;
}
.ant-select-dropdown .ant-select-item:last-child {
  border-bottom: none !important;
}
.ant-select-dropdown .ant-select-item .ant-select-item-option-content span {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #b5bac5 !important;
  margin-left: 4px !important;
}
.ant-select-dropdown .ant-select-item:hover {
  background: #3a4047 !important;
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #8c94a4 !important;
  background: #3a4047 !important;
  cursor: not-allowed;
}
/* .ant-select-selection-search {
  padding: 11px 16px !important;
  height: 48px !important;
  font-family: "Inter", sans-serif !important;
} */
.ant-select-item
  .ant-select-item-option
  .ant-select-item-option-active
  .ant-select-item-option-selected {
  background: #25272d !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  inset-inline-start: 0px !important;
  inset-inline-end: 0px !important;
}
.ant-select-arrow {
  display: none !important;
}
.ant-select-dropdown
  .ant-slide-up-leave
  .ant-slide-up-leave-active
  .ant-slide-up {
  border-radius: 4px !important;
  border: 1px solid #63676c !important;
  background: #32353d !important;
}
.ant-empty-description {
  color: #8c94a4 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

:where(.css-only-do-not-override-xu9wm8).ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: #8c94a4 !important;
  background: #556477 !important;
}

:where(.css-xu9wm8).ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: #8c94a4 !important;
  background: #556477 !important;
}

/* data-limit selector */
.data-limit-select .ant-select-single.ant-select .ant-select-selector:after {
  width: 20px !important;
  height: 20px !important;
  background-image: url("../../../../public/assets/images/data-limit-select-downarrow.svg");
  background-repeat: no-repeat;
  background-position: left;
  transform: none !important;
  border: none !important;
  visibility: visible !important;
  content: "" !important;
}

.data-limit-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search {
  display: none;
}

.data-limit-select .ant-select-single .ant-select-selection-item span {
  color: #0f1014 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  margin-left: 0px !important;
}

.data-limit-select .ant-select-selector {
  align-items: center;
  gap: 0.5rem;
  border-radius: 8px;
  border: 1px solid #63676c !important;
  background-color: #faf7f7 !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px 10px !important;
}

.data-limit-select .ant-select-single {
  height: 44px;
  min-width: 74px;
}

.data-limit-select .ant-select-selection-item > div {
  justify-content: center;
}

/* theme-form-select */
.theme-form-select .ant-select-single {
  height: 48px;
}

.theme-form-select .ant-select-selection-item img {
  margin-right: 4px;
}

.theme-form-select .ant-select-selector {
  align-items: center;
  gap: 0.5rem;
  background-color: #32353d !important;
  border: 1px solid #63676c !important;
  caret-color: #fff;
  border-radius: 4px !important;
  padding: 0px 16px !important;
}

.theme-form-select .ant-select-single.ant-select .ant-select-selector:after {
  width: 20px !important;
  height: 20px !important;
  background-image: url("../../../../public/assets/images/keyboard-arrow.svg");
  background-repeat: no-repeat;
  background-position: left;
  transform: none !important;
  border: none !important;
  visibility: visible !important;
  content: "" !important;
}

.theme-form-select .ant-select-selection-search {
  padding: 12px 16px !important;
}

.theme-form-select .ant-select-selection-item span {
  margin-left: 0px !important;
  color: #b5bac5 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.theme-form-select .ant-select-selection-search input {
  color: #b5bac5 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
}

/* publish-hidden dropdown */
.theme-form-select.profle-status-dropdown .ant-select-single {
  height: 36px;
  min-width: 134px;
}

.theme-form-select.profle-status-dropdown
  .ant-select-single
  .ant-select-selection-search {
  height: 36px !important;
}

/* .theme-form-select.profle-status-dropdown .ant-select-selection-search {
        padding: 7px 24px !important;
    } */

.theme-form-select.profle-status-dropdown .ant-select-selector {
  align-items: center;
  gap: 0.5rem;
  background-color: transparent !important;
  border: 1px solid #faf7f7 !important;
  caret-color: #fff;
  border-radius: 12px !important;
  padding: 0px 16px 0px 24px !important;
}

.theme-form-select.profle-status-dropdown .ant-select-selection-item span {
  color: #e0dbdb !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.theme-form-select.profle-status-dropdown
  .ant-select
  .ant-select-selector:after {
  background-image: url("../../../../public/assets/images/white-down-arrow.svg");
}

.profile-status-dropdown-listing.ant-select-dropdown
  .ant-select-item
  .ant-select-item-option-content
  span {
  color: #e0dbdb !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.profile-status-dropdown-listing.ant-select-dropdown .ant-select-item {
  padding: 6px 24px !important;
}

.profile-status-dropdown-listing.ant-select-dropdown {
  border-radius: 12px !important;
}
